import React from "react";
import banner from "../assets/banner.jpeg";

const Banner = () => {
  return (
    <div>
      <img
        className="relative -z-20 h-screen w-full object-cover"
        src={banner}
        alt=""
      />
    </div>
  );
};

export default Banner;
