import React from "react";
import logo from "../assets/logo.jpeg";

const Navbar = () => {
  return (
    <div className="sticky top-0 -z-10 h-[6.3rem] bg-black text-white">
      <div className="mainContainer flex justify-between items-center h-full">
        <img
          className="w-[4.5rem] object-cover rounded-md cursor-pointer"
          src={logo}
          alt="logo"
        />

        <div className="uppercase text-xs xl:text-lg">
          powered by Silverline AI
        </div>
      </div>
    </div>
  );
};

export default Navbar;
